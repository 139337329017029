$(document).on("ready", function () {
  $(".conversation-tabs").tabs({
    select: function (event, ui) {
      $(ui.panel).animate({ opacity: 0.1 });
    },
    show: function (event, ui) {
      $(ui.panel).animate({ opacity: 1.0 }, 1000);
    },
  });

  $(".custom_carousel").slick({
    dots: true,
    centerMode: false,
    rtl: $("html").hasClass("rtl"),
  });

  $(".gallery-category-menu-and-dropdown select").change(function () {
    window.location = $(this).find("option:selected").val();
  });

  $(".custom_carousel-tranding").slick({
    infinite: false,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  });
});

$(".add-more-location-box").not(":first").remove();

$(function () {
  toggleCitySelection();
  return $(document).on("input", ".rails-country-for-select", function (e) {
    var $this = $(this);
    return $.ajax({
      url: "/filter_cities",
      type: "GET",
      dataType: "script",
      data: {
        country_for_select: $this.val(),
        // target_element: $this.uniqueId().attr('id')
        target_element: $this.attr("id"),
      },
    });
  });
});

function toggleCitySelection() {
  var countrySelect = $(".rails-country-for-select");

  if ($(countrySelect).val()) {
    setTimeout(function () {
      $(".js-city").prop("disabled", false);
    }, 1000);
  } else {
    $(".js-city").prop("disabled", true);
  }
}

$(".rails-country-for-select").on("input", toggleCitySelection);
$(document).on("ready", function () {
  $("#accordion")
    .find(".accordion-toggle")
    .unbind()
    .click(function () {
      var isActive = $(this).hasClass("active");
      $(".accordion-toggle").removeClass("active");
      if (!isActive) {
        $(this).toggleClass("active");
      }

      $(this).next().slideToggle("fast");
      //Hide the other panels
      $(".accordion-content").not($(this).next()).slideUp("fast");
    });

  function show(value) {
    document.querySelector(".text-box").value = value;
  }

  let dropdown = document.querySelector(".dropdown");
  $(dropdown).click(function () {
    dropdown.classList.toggle("active");
  });
});

import _ from "./src/lodash";
import smoothScroll from "smooth-scroll";

$(window)
  .bind("resize", function () {
    if ($(this).width() > 803) {
      $(".user-list-popover, .language-icon").show();
      $("body").removeClass("fixed-position");
      $(".js-mobile-nav-backdrop").removeClass("is-visible");
      $(".js-mobile-nav").removeClass("is-visible");
      $(".main-nav").removeClass("main-nav-show");
      $(".small-screen-search-sidebar").hide();
      $(".filter-sidebar-menu").removeClass(
        "animate-sub-category-bar",
        function () {
          $(".filter-sidebar-menu-overlay").removeClass(
            "animate-sub-category-bar-overlay"
          );
        }
      );
      // $( ".messages-card" ).closest('.grid').addClass('grid')
      if (location.pathname == "room" || location.pathname == "media_files") {
        location.pathname = "user/inbox";
      }
    } else {
      $(".user-list-popover, .language-icon").hide();
      // $( ".messages-card" ).closest('.grid').removeClass('grid')
    }
  })
  .trigger("resize");

document.addEventListener("turbo:load", () => {
  const elements = document.querySelectorAll("[data-turbo-scroll]");

  elements.forEach(function (element) {
    element.addEventListener("click", () => {
      Turbo.navigator.history.replace(Turbo.navigator.location);
    });

    element.addEventListener("submit", () => {
      Turbo.navigator.history.replace(Turbo.navigator.location);
    });
  });

  Turbo.navigator.history.scrollRestoration = "manual";
});

document.addEventListener("turbo:load", function () {
  $(".blur-main .blur-image").contextmenu(function (e) {
    e.preventDefault();
  });
  $("#business-tabs-1, #business-tabs-2, #business-tabs-3").tabs({
    show: 300,
    hide: 300,
  });

  $(function () {
    maxL = 100;
    $(".read-more .small").each(function () {
      var text = $(this).text();
      if (text.length > maxL) {
        var begin = text.substr(0, maxL),
          end = text.substr(maxL);
        $(this)
          .html(begin)
          .append($('<span class="readmore"/>').html("Read More..."))
          .append($('<div class="content-hidden" />').html(end));
      }
    });
    $(document).on("click", ".readmore", function () {
      $(this).next(".content-hidden").slideToggle(400);
    });
  });

  $(function () {
    var icons = {
      header: "iconClosed", // custom icon class
      activeHeader: "iconOpen", // custom icon class
    };
    $(".current-project").accordion({
      icons: icons,
      collapsible: true,
      animate: 500,
      active: false,
      heightStyle: "content",
    });
  });

  window.addEventListener(
    "popstate",
    () => {
      location.reload();
    },
    false
  );

  $(".message-today").not(":first").remove();

  bMyDiv = document.getElementById("chat-scroller");
  if (bMyDiv != null) {
    bMyDiv.scrollTop = bMyDiv.scrollHeight;
  }
  sMyDiv = document.getElementById("chat");
  if (sMyDiv != null) {
    sMyDiv.scrollTop = sMyDiv.scrollHeight;
  }
  // sticky element using js
  setTimeout(() => {
    window.onscroll = function () {
      myFunction();
    };
  }, 2000);
  var navbar = document.getElementById("custom-stick");
  if (navbar !== null) {
    var sticky = navbar.offsetTop;
  }
  function myFunction() {
    if (
      window.pageYOffset >= sticky &&
      $(".ui-state-active").attr("aria-controls") == "tabs-1"
    ) {
      navbar.classList.add("custom-sticky");
    } else {
      if ($(navbar).hasClass("custom-sticky")) {
        navbar.classList.remove("custom-sticky");
      }
    }
  }
  // sticky element using js
  /* -------------------------------------------------------------------------- */
  /*                               file attachment                              */
  $(".user-image").hide();
  $(".edit-attahments .camera-img").show();
  $(document).on("click", ".input-file-upload", function () {
    that = $(this);
    $(that)
      .parent()
      .on("change", ".mult-file-upload", function () {
        reader = new FileReader();
        file = $(this).get(0).files[0];
        reader.onload = function (e) {
          $(that).parent().find(".user-image").attr("src", e.target.result);
          $(that).parent().find(".attachment-icon, .file-content").hide();
          $(that).parent().find(".user-image").show();
        };
        reader.readAsDataURL(file);
      });
  });
  /* -------------------------------------------------------------------------- */
  closeOnSelect = function () {
    return false;
  };

  $(".menu-back-icon").click(function () {
    $(".user-information-side-menu")
      .toggle("slide", "fast")
      .toggleClass("user-information-side-menu-sidebar ");
  });
  $(".businesses-toggle").click(function () {
    $(".user-all-businesses")
      .toggle("slide", "fast")
      .toggleClass("user-information-side-menu-sidebar ");
  });
  $(".toggle-sidebar-search").click(function () {
    $(".small-screen-search-sidebar").toggle();
  });

  $.sidebarMenu = function (menu) {
    var animationSpeed = 300;

    $(menu).on("click", "li a", function (e) {
      var $this = $(this);
      var checkElement = $this.next();

      if (checkElement.is(".treeview-menu") && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("menu-open");
        });
        checkElement.parent("li").removeClass("active");
      }

      //If the menu is not visible
      else if (
        checkElement.is(".treeview-menu") &&
        !checkElement.is(":visible")
      ) {
        //Get the parent menu
        var parent = $this.parents("ul").first();
        //Close all open menus within the parent
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        //Remove the menu-open class from the parent
        ul.removeClass("menu-open");
        //Get the parent li
        var parent_li = $this.parent("li");

        //Open the target menu and add the menu-open class
        checkElement.slideDown(animationSpeed, function () {
          //Add the class active to the parent li
          checkElement.addClass("menu-open");
          parent.find("li.active").removeClass("active");
          parent_li.addClass("active");
        });
      }
      //if this isn't a link, prevent the page from being redirected
      if (checkElement.is(".treeview-menu")) {
        e.preventDefault();
      }
    });
  };

  $.sidebarMenu($(".min-sidebar-menu"));

  $(".category_select_item").on("click", function (e) {
    var that = $(this);

    // if don't want multi category select then please remove the 'multi-category-select' class from html element
    if (
      !that.hasClass("category_selected") &&
      !that.hasClass("multi-category-select")
    ) {
      $(".category_selected").removeClass("category_selected");
      that.addClass("category_selected");
      var category = that.data("category"),
        project = that.data("project"),
        url =
          "/user/projects/category_details?category_id=" +
          category +
          "&project_id=" +
          project;

      $("#category_details_all").html(
        "<div class='loading_img_wrapper'><img src='https://public-assets-muqa.s3-ap-southeast-1.amazonaws.com/loading_main.gif' /></div>"
      );
      $("#category_details_all").load(url + "#card-wrapper", function () {
        $(this).find("svg").hide();
        $(this)
          .find(".card-middle .card__section .card__header")
          .first()
          .addClass("grid");
        $(this)
          .find(".card-middle .card__section .card__header div.card__title")
          .first()
          .addClass("grid__col--xs-12 grid__col--md-12 grid__col--lg-12");
        $(this)
          .find(".card-middle .card__section .fieldset  .field")
          .first()
          .addClass("grid");
        $(this)
          .find(".card-middle .card__section.js-select-services .card__header")
          .addClass("mt-4 grid");
        $(this)
          .find(
            ".card-middle .card__section.js-select-services .card__header div.card__title"
          )
          .addClass("grid__col--xs-12 grid__col--md-lg");
        $(this)
          .find(".card-middle .card__section.js-select-services .fieldset")
          .addClass("grid");
        $(this)
          .find(
            ".card-middle .card__section.js-select-services .fieldset .field  label"
          )
          .hide();

        $(".product_hidden_category").val(category);
        $("#category_details_all .select2")
          .addClass("needsclick select-me")
          .select2({
            templateSelection: hideNone,
            templateResult: hideNone,
            closeOnSelect: false,
          });

        services = $(this).find("#project_project_services");
        $(services).find("option").first().text("Select");
        $(services).parent().siblings().first().show();

        var selectedSubCat = "";

        $("#category_details_all .js-selected-services").on(
          "change",
          function () {
            if ($(this).val() && $(this).val().length) {
              $("#category_details_all .js-submit").prop("disabled", false);
            }
          }
        );

        $("#category_details_all .js-submit").prop("disabled", true);

        $("#category_details_all .js-selected-subcat").on(
          "change",
          function () {
            selectedSubCat = parseInt($(this).val());
            $.each($("#category_details_all .js-select-services"), function () {
              if ($(this).data("sub-cat") === selectedSubCat) {
                $(this).removeClass("u-hide");
              } else {
                $(this).addClass("u-hide");
                $(this).find("select").val("").change();
              }
            });
          }
        );
      });
    }
  });
  // Tooltips
  $(".tooltip").tooltipster({
    maxWidth: 500,
  });

  // Datepicker
  $(".datepicker").datepicker({
    dateFormat: "dd-mm-yy",
    changeYear: true,
    yearRange: "1900:2016",
  });
  $(".datepicker-future").datepicker({
    dateFormat: "dd-mm-yy",
    changeYear: true,
    yearRange: "2016:2025",
    beforeShowDay: stylePastDates,
  });

  // Select2
  $(".select2").addClass("needsclick select-me").select2({
    templateSelection: hideNone,
    templateResult: hideNone,
    closeOnSelect: false,
  });

  // Segmented control
  $(".segmented-control__item").each(function (o) {
    $(this).click(function () {
      $(".segmented-control__item").siblings().removeClass("active");
      $(this).addClass("active");
    });
  });

  // Mobile nav
  $(".js-mobile-nav-trigger").click(function () {
    $(".js-mobile-nav").toggleClass("is-visible");
    $(".js-mobile-nav-backdrop").toggleClass("is-visible");
    $(".main-nav").toggleClass("main-nav-show");

    // $('body').toggleClass('hidden');
    if ($(".js-mobile-nav-backdrop").hasClass("is-visible")) {
      $("body").addClass("fixed-position");
    } else {
      $("body").removeClass("fixed-position");
    }
  });

  // Banner
  $(".banner--disappearing").delay(7000).slideUp(300);

  // How it works
  $(".js-how-it-works-trigger").click(function () {
    $(".js-how-it-works").slideToggle(300);
  });

  // Dropdown
  var dropdownDelayHandles = {}; // This associates popover buttons with their popover timeout handles. It allows us to clear only the relevant setTimeouts when a user's mouse exits a popover.
  var i = 0;
  var setPopoverTimeout = function ($popover, $popoverTrigger) {
    dropdownDelayHandles[$popoverTrigger.data("popover-id")] = setTimeout(
      function () {
        $popover.removeClass("is-visible");
      },
      200
    );
  };
  var clearPopoverTimeout = function ($popoverTrigger) {
    var timeoutId = dropdownDelayHandles[$popoverTrigger.data("popover-id")];
    clearTimeout(timeoutId);
    delete dropdownDelayHandles[$popoverTrigger.data("popover-id")]; // Remove the unused ID from the handle object
  };

  // Give each popover link a unique ID to use them as keys in dropdownDelayHandles
  $(".js-open-popover").each(function () {
    $(this).data("popover-id", i++);
  });

  $(".js-open-popover").mouseenter(function () {
    clearPopoverTimeout($(this));
    $(this).next(".popover").addClass("is-visible");
  });

  $(".js-open-popover").mouseleave(function () {
    setPopoverTimeout($(this).next(".popover"), $(this));
  });

  $(".popover").mouseenter(function () {
    clearPopoverTimeout($(this).prev(".js-open-popover"));
  });

  $(".popover").mouseleave(function () {
    setPopoverTimeout($(this), $(this).prev(".js-open-popover"));
  });

  $(".js-open-popover").on("touchstart", function () {
    $(this).next(".popover").toggleClass("is-visible");
  });

  // Smooth Scroll
  $(function () {
    const scroll = new smoothScroll(
      'a[href*="#"]:not([class*="business-tabs"]):not([class*="admin-tabs"])',
      {
        speed: 300,
      }
    );
  });

  // Slick Slider

  $(".js-ad-slider").slick({
    arrows: false,
    speed: 200,
    cssEase: "ease-in-out",
  });

  $(".js-ad-slider-prev").click(function () {
    $(".js-ad-slider").slick("slickPrev");
  });

  $(".js-ad-slider-next").click(function () {
    $(".js-ad-slider").slick("slickNext");
  });

  // Sidebar expand

  $(".js-sidebar-expand").click(function () {
    var $this = $(this);

    if ($this.hasClass("expanded")) {
      $this.removeClass("expanded");
      $this.next(".js-sidebar-list").hide();
      return;
    }

    $(document)
      .find(".js-sidebar-list")
      .each(function () {
        $(this).hide();
      });
    $(document)
      .find(".js-sidebar-expand")
      .each(function () {
        $(this).removeClass("expanded");
      });
    $(this).toggleClass("expanded");
    $(this).next(".js-sidebar-list").toggle();
  });

  //Show or hide chat for a project on project#show page

  $(".js-toggle-chat").on("click", function () {
    var listingCard = $(this).parents(".js-project-listing-card");
    var chatCard = listingCard.find(".js-project-card-chat");

    if (chatCard.hasClass("u-hide")) {
      chatCard.removeClass("u-hide");
      $(this).text("Hide messages");
    } else {
      chatCard.addClass("u-hide");
      $(this).text("View messages");
    }
  });

  // Admin country switcher

  $(".js-admin-country-selector").change(function () {
    $(this).closest("form").submit();
  });

  // Submit forms with <a> tags
  $(".js-form-submit-link").click(function (e) {
    $(this).closest("form").submit();
    e.preventDefault();
  });
});

// Toggle follow
$(document).on("click", ".js-toggle-follow", function () {
  $(this).toggleClass("btn--secondary").toggleClass("btn--positive");

  if ($(this).children(".js-following").hasClass("u-hide")) {
    $(this).children(".js-following").removeClass("u-hide");
    $(this).children(".js-follow").addClass("u-hide");
  } else {
    $(this).children(".js-following").addClass("u-hide");
    $(this).children(".js-follow").removeClass("u-hide");
  }
});

// Toggle section in business listing profile
$(document).on("click", ".js-section-toggle", function () {
  $(".js-section-toggle").each(function () {
    $(this).removeClass("is-active");
  });
  $(this).addClass("is-active");

  if ($(this).data("toggle") === "overview") {
    $(".js-section").each(function () {
      $(this).removeClass("u-hide");
    });
    $(".js-empty").each(function () {
      $(this).addClass("u-hide");
    });
  } else {
    $(".js-section").each(function () {
      $(this).addClass("u-hide");
    });
    $(document)
      .find(".js-" + $(this).data("toggle"))
      .removeClass("u-hide");
    var view_more = $("body").data("view-more");
    var view_less = $("body").data("view-less");
    $(".js-truncate-text").readmore({
      speed: 200,
      collapsedHeight: 100,
      moreLink:
        '<p class="u-text-accent"><a href="#">+' + view_more + "</a></p>",
      lessLink:
        '<p class="u-text-accent"><a href="#">-' + view_less + "</a></p>",
    });
  }
});

// Callback - change number
$(document).on("click", ".js-change-number", function () {
  $(".js-hide-number").toggle();
  $(".js-replace-number").toggle();
  $("#user_callback_user_number").toggle();
});

// Check if business update form has been modified
function unlockBusinessForm() {
  $(this).find('input[type="submit"]').prop("disabled", false);
}

$(document).on(
  "input",
  ".edit_business, .new_business",
  _.debounce(unlockBusinessForm, 300)
);

// Expand featured projects in business profile edit
$(document).on("click", ".js-toggle-featured-project", function () {
  $(this)
    .parent(".js-featured-project")
    .children(".js-toggle-target")
    .toggleClass("u-hide");
});

// Mark a notification as read
$(document).on("click", ".js-mark-as-read", function () {
  $this = $(this);
  $.ajax({
    url: "/business/notifications/mark_as_read",
    method: "PUT",
    data: {
      notification: $this.data(),
    },
  });
});

// Select2 - select all
$(document).on("change", ".js-select-all", selectAll);

function selectAll() {
  var select2Target = $(this).parents(".js-select2");
  var select2Options = select2Target.find(".js-select2-options > option");

  if ($(this).is(":checked")) {
    select2Options.prop("selected", "selected");
  } else {
    select2Options.removeAttr("selected");
  }

  select2Target.find(".js-select2-options").trigger("change");
}

//Hide none from select2 results (templateSelection, templateResult)
function hideNone(data, container) {
  if (data.text === "None") {
    $(container).addClass("u-hide");
  } else {
    return data.text;
  }
}

// Datatables - Set default options
$.extend(true, $.fn.dataTable.defaults, {
  language: {
    info: "Showing _TOTAL_ entries",
    infoEmpty: "No entries to show",
    infoFiltered: "(filtered from _MAX_)",
  },
});

// Phone Number - format
$(document).on("keyup", ".js-phone", function () {
  $(this).val($(this).val().replace(/^00/, "+"));
});

// Datepicker - style past dates

function stylePastDates(date) {
  if (moment(date).isBefore(moment().subtract(1, "day"))) {
    return [false, "past-date"];
  }
  return [true, ""];
}

// Trim string
//
function trimString(string) {
  if (string.length < 50) {
    return string;
  }

  return (
    $.trim(string).substring(0, 50).split(" ").slice(0, -1).join(" ") + "..."
  );
}

// Open image
$(document).on("click", ".js-open-image", function () {
  window.open($(this).data().photolink);
});

$(document).on("click", ".js-open-image-inner", function (e) {
  e.stopPropagation();
});

function ChatFuncation() {
  $('[data-channel-subscribe="conversation"]').each(function (index, element) {
    var $element = $(element);
    var conversation_id = $element.data("conversation-id");
    var business_id = $element.data("business-id");

    if (!conversation_id) {
      console.log("Missing conversation_id, skipping channel subscription");
      return;
    }

    console.log(
      "Attempting to subscribe to conversation:",
      conversation_id,
      "business:",
      business_id
    );

    messageTemplate = $('[data-role="message-template"]');

    $element
      .parent("#chat-scroller")
      .animate(
        { scrollTop: $element.parent("#chat-scroller").prop("scrollHeight") },
        1500
      );

    // Simplify subscription process - use window.App.cable directly
    if (window.App && window.App.cable) {
      var subscription = window.App.cable.subscriptions.create(
        {
          channel: "ConversationChannel",
          conversation: conversation_id,
          business: business_id,
        },
        {
          received: function (data) {
            console.log("Received message:", data);
            var activeTab = $(".tab-item-wrap[aria-hidden=false]");
            if (data.sending_user_type == "User") {
              if (activeTab.attr("id") == "tabs-1") {
                var messageRecevier = ".set-business-" + data.receiving_user_id;
                var messageList = ".set-project-" + data.project_id;
                $(messageList)
                  .find($(messageRecevier))
                  .each(function () {
                    $(this).find("h5 small").text(data.message_date);
                    $(this).find("p").text(data.body);
                    $(this)
                      .parent()
                      .parent()
                      .find("p")
                      .first()
                      .text(data.body)
                      .append(
                        "<i class='fa fa-chevron-right drop-toggle-icon '></i>"
                      );
                    $(this).prependTo($(this).parent());
                    $(this)
                      .parent()
                      .parent()
                      .parent()
                      .prepend($(this).parent().parent());
                  });
              }
              if (activeTab.attr("id") == "tabs-2") {
                $(activeTab)
                  .find(".message-active")
                  .each(function () {
                    $(this).find("h5 small").text(data.message_date);
                    $(this).find("p").text(data.body);
                    $(this).prependTo($(this).parent());
                  });
              }
            } else {
              if (activeTab.attr("id") == "tabs-1") {
                var messageRecevier = ".set-business-" + data.sending_user_id;
                var messageList = ".set-project-" + data.project_id;
                $(messageList)
                  .find($(messageRecevier))
                  .each(function () {
                    $(this).find("h5 small").text(data.message_date);
                    $(this).find("p").text(data.body);
                    $(this)
                      .parent()
                      .parent()
                      .find("p")
                      .first()
                      .text(data.body)
                      .append(
                        "<i class='fa fa-chevron-right drop-toggle-icon '></i>"
                      );
                    $(this).prependTo($(this).parent());
                    $(this)
                      .parent()
                      .parent()
                      .parent()
                      .prepend($(this).parent().parent());
                  });
              }
              if (activeTab.attr("id") == "tabs-2") {
                $(activeTab)
                  .find(".message-active")
                  .each(function () {
                    $(this).find("h5 small").text(data.message_date);
                    $(this).find("p").text(data.body);
                    $(this).prependTo($(this).parent());
                  });
              }
            }
            // clear the input field and atatchment field
            $(".text-message-view").text("");
            $(".js-file-upload").val("");
            $(".image-viewer-iframe").attr("src", "");
            $(".remove-blog").hide();

            var message_view = get_message_view(data);
            $element.append(message_view);
            $element.parent("#chat-scroller").animate(
              {
                scrollTop: $element
                  .parent("#chat-scroller")
                  .prop("scrollHeight"),
              },
              1500
            );
          },
          speak: function (data) {
            // clear the input field and atatchment field
            $(".text-message-view").text("");
            $(".js-file-upload").val("");
            $(".image-viewer-iframe").attr("src", "");
            $(".remove-blog").hide();

            var message_view = get_message_view(data);
            $element.append(message_view);

            $element.parent("#chat-scroller").animate(
              {
                scrollTop: $element
                  .parent("#chat-scroller")
                  .prop("scrollHeight"),
              },
              1500
            );
          },
          connected: function () {
            console.log(
              "Connected to ConversationChannel for conversation:",
              conversation_id
            );
          },
          disconnected: function () {
            console.log(
              "Disconnected from ConversationChannel for conversation:",
              conversation_id
            );
          },
        }
      );
      console.log("Subscription created", subscription);
    } else {
      console.error(
        "ActionCable not initialized. window.App.cable is not available."
      );
    }
  });

  function get_message_view(data) {
    var current_user_id = $(".send-message-template").data("current_user_id");
    var current_business_id = $(".send-message-template").data(
      "current_business_id"
    );
    if (data.sending_user_id == current_user_id) {
      var template = $(".send-message-template").children().clone(true, true);
    } else {
      if (data.sending_user_id == current_business_id) {
        if (data.sending_user_id == current_business_id) {
          var template = $(".send-message-template")
            .children()
            .clone(true, true);
        }
        if (data.receiving_user_id == current_user_id) {
          var template = $(".receiver-message-template")
            .children()
            .clone(true, true);
          template.find(".receiver-profile-photo").removeClass("u-hide");
          template
            .find(".receiver-profile-photo")
            .attr("src", data.sender_profile_url);
        }
      } else {
        var template = $(".receiver-message-template")
          .children()
          .clone(true, true);
        template.find(".receiver-profile-photo").removeClass("u-hide");
        template
          .find(".receiver-profile-photo")
          .attr("src", data.sender_profile_url);
      }
    }

    if (data.attachment) {
      if (data.attachment["is_image_type?"] == "0") {
        template.find(".image-tag-element").removeClass("u-hide");
        template.find(".image-tag-element").attr("src", data.attachment.url);
      } else if (data.attachment["is_video_type?"] == "0") {
        template.find(".video-tag-element").removeClass("u-hide");
        template.find(".video-tag-element").attr("src", data.attachment.url);
      } else if (data.attachment["is_audio_type?"] == "0") {
        template.find(".audio-tag-element").removeClass("u-hide");
        template.find(".audio-tag-element").attr("src", data.attachment.url);
      } else {
        template.find(".other-attachments-tag-element").removeClass("u-hide");
        template
          .find(".other-attachments-tag-element")
          .append(" " + data.attachment.attachment_file_name);
      }
    } else {
      template.find(".text-message-element").removeClass("u-hide");
      template.find(".text-message-element").text(data.body);
      template.find(".receiver-time").removeClass("u-hide");
      template
        .find(".receiver-time")
        .text(data.created_at_message)
        .css({ "min-width": "100px", "text-align": "left" });
    }

    return template;
  }
}

// Initialize on document ready - call the function immediately
$(document).ready(function () {
  console.log("Document ready, initializing ChatFuncation");
  ChatFuncation();
});

// Also keep the original event handler
$(document).on("ready", function (e) {
  console.log("Document on-ready event, initializing ChatFuncation");
  ChatFuncation();
});

$(document).on(
  "click",
  ".message-contact-list_company_name.chat-drop-list",
  function (e) {
    if ($(window).width() > 768) {
      $("#conversation-messages").html(
        "<div class='loading_img_wrapper'><img src='https://public-assets-muqa.s3-ap-southeast-1.amazonaws.com/loading_main.gif' /></div>"
      );
    }
  }
);

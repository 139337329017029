$(document).on("ready", function () {
  $(".admin-sidebar-toggle").click(function () {
    $(".admin-sidebar-menu").toggleClass("new-span0").toggleClass("span1");
    $(".my-admin-right, .admin-body > .nav.primary-nav")
      .toggleClass("full-width")
      .toggleClass("span1");
    $(".admin-body .nav .u-text-right")
      .toggleClass("grid__col--lg-6")
      .css({ transition: "all 1000ms" });
  });
});

// External libraries and dependencies
import Rails from "@rails/ujs";
Rails.start();

import "./src/add_jquery";
import "./src/jquery-ui";
import _ from "./src/lodash";
window._ = _;
import select2 from "select2";
select2();
import "slick-carousel";
import "@googlemaps/markerclustererplus";
import "gmaps";
import "datatables.net";
import "trix";
import "@rails/actiontext";
import "readmore-js";
import moment from "moment";
window.moment = moment;
import "tooltipster";
import "highcharts";
import rivets from "rivets";
window.rivets = rivets;
// import "rails_emoji_picker"; TODO

// Turbo Rails should be loaded after all dependencies
import "@hotwired/turbo-rails";

// ActionCable setup
import "./channels/consumer";
import "./channels/index";

// Other custom scripts and modules
import "cocoon";

// ... other imports

// Your application-specific scripts
import "./modals";
import "./truncation";
import "./main";
import "./browser-geolocation";
import "./photo-control";
import "./gallery";
import "./admin-custom";
import "./cable"; // Make sure this comes after channels setup
import "./conversation";
import "./auto_complete";
import "./business-map";
import "./business-profile";
import "./custom_methods";
import "./custom";
import "./guest-add-business";
// import "./overview-stats";
import "./project-services";
import "./sticky-nav";
import "./user_dashboard";
// ... other imports
import "@nathanvda/cocoon";

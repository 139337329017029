$(function () {
  $("#new_message").on("ajax:success", function (a, b, c) {
    console.log("Message sent successfully");
    $(this).find("input")[0].form.reset();
    $("div.string.optional").text("");
  });
});

// Flag to prevent multiple submissions in quick succession
let submissionInProgress = false;

$(document).ready(function () {
  // Handle input field changes to enable/disable button
  $("#new_message").on("keyup change", function (e) {
    var txt_ = e.target.value;

    if (txt_.length == 0) {
      $(".my-button").attr("disabled", "disabled");
    } else {
      $(".my-button").attr("disabled", false);

      // Only handle Enter key if it's not already being processed
      if (e.key === "Enter" && !submissionInProgress) {
        e.preventDefault(); // Prevent default Enter behavior
        submissionInProgress = true; // Set flag to prevent duplicate submissions

        $("#describe-your-request").val(txt_);

        // Submit form and reset when complete
        $("#new_message")
          .submit()
          .promise()
          .done(function () {
            $(this).find("input")[0].form.reset();
            $("div.string.optional").text("");

            // Reset flag after a short delay
            setTimeout(function () {
              submissionInProgress = false;
            }, 500);
          });
      }
    }
  });

  // Also handle the button click to set the same flag
  $(".my-button").on("click", function () {
    submissionInProgress = true;

    // Reset flag after submission completes
    setTimeout(function () {
      submissionInProgress = false;
    }, 500);
  });
});

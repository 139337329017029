// Import the Action Cable consumer
import { createConsumer } from "@rails/actioncable";

// Create a consumer immediately
const cableConsumer = createConsumer();

// Ensure the App namespace is defined
window.App = window.App || {};

// Initialize App.cable immediately
window.App.cable = cableConsumer;

// Log connection status to help debugging
console.log("ActionCable initialized: ", window.App.cable);

// Keep the click handler for backward compatibility
$(document).on(
  "click",
  ".message-contact-list_company_name.chat-drop-list",
  function (e) {
    // Make sure App.cable is set
    if (!App.cable) {
      App.cable = cableConsumer;
      console.log("ActionCable re-initialized on click");
    }
  }
);

// $( document ).on('ready', function() {
// $(".project-thumb-actions").on("click", function(e){
//     console.log("clicked");
// var that = $(this),
// actionList = that.next(".project-actions-list");
// actionList.toggle();
// });
// });
$(document).on("click", ".project-thumb-actions", function () {
  var that = $(this),
    actionList = that.next(".project-actions-list");
  actionList.toggle();
});

$(document).on("click", ".actions-list-link", function () {
  $(this).parents(".project-actions-list").toggle();
});
